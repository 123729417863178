<template>
  <div class="details_title inner clearfix">
    <div class="fl datails-left">
      <p>{{ data.file_name }}</p>
      <p>教材：{{ data.book_name }}/{{ data.file_name }}</p>
      <p>
        <span v-if="(data.file_size / 1024).toFixed(0) < 1024"
          >文件大小：{{ (data.file_size / 1024).toFixed(0) }} KB</span
        >
        <span v-else
          >文件大小：{{ (data.file_size / 1024 / 1024).toFixed(0) }}MB</span
        >
        <span>发布时间：{{ data.create_time }}</span>
        <span>下载次数：{{ data.downnum }}</span>
        <span>上传者：{{ data.upload_uname }}</span>
      </p>
    </div>
    <div class="fr datails-right">
      <div>
        <el-button round type="primary" @click="detailsUpdown()"
          >下载</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { rand } from "../../api/http";
import axios from "axios";
import URL from "@/api/index.js";
export default {
  props: ["switch_data"],
  name: "",
  data() {
    return {
      data: [],
    };
  },
  watch: {
    switch_data(val, old) {
      if (val != old) {
        this.data = val;
      }
    },
  },
  mounted() {
    this.data = JSON.parse(window.localStorage.getItem("item_xiangqing"));
  },
  methods: {
    detailsUpdown() {
      // console.log(this.data.file_id)
      // this.getRecordRjw(
      //   JSON.parse(localStorage.getItem("loginInfo")),
      //   JSON.parse(localStorage.getItem("item_xiangqing"))
      // );
      var params = {
        fileid: this.data.file_id,
      };
      this.$get(URL.fileDown, params).then((res) => {});
      window.location.href = this.data.download_url;
    },
    // 数据上报
    getRecordRjw(hh, xx) {
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime =
          res.data.result.net_ts - Date.parse(new Date()) / 1000;
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "resource_download",
          user_id: String(hh.user_id),
          user_role: String(hh.user_type),
          user_name: String(hh.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${hh.user_nicename}` + "下载了" + `${xx.file_name}`,
          data: {
            school_name: hh.teacher_info.school_name
              ? `${hh.teacher_info.school_name}`
              : "",
            school_id: hh.teacher_info.school_id
              ? `${hh.teacher_info.school_id}`
              : "",
            grade_name: JSON.parse(localStorage.getItem("data_nianji")),
            grade_id: JSON.parse(localStorage.getItem("zjgradeCopy")),
            subject_name: JSON.parse(localStorage.getItem("data_kemu")),
            subject_id: JSON.parse(localStorage.getItem("zjsidCopy")),
            textbook_id: JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name: xx.book_name,
            file_name: xx.file_name,
            trace_id:JSON.parse(localStorage.getItem("resource_traceid")),
            file_type: localStorage.getItem("file_type"),
            file_format: localStorage.getItem("file_fmt"),
            res_type: JSON.parse(localStorage.getItem("data_ziyuantype")),
            chapter_id: xx.chapter_id,
            chapter_name: JSON.parse(localStorage.getItem("data_zjname")),
          },
        };
        axios.post("http://47.114.114.56:9002/RecordRjw", saydata);
      });
    },
  },
};
</script>


<style scoped>
.details_title {
  background-color: #fff;
}
.datails-left {
  padding: 16px 0 13px 30px;
}
.datails-left p:first-child {
  font-size: 18px;
  color: #2f2f2f;
  line-height: 38px;
}
.datails-left p:nth-child(2) {
  font-size: 14px;
  line-height: 34px;
  color: #6e7578;
}
.datails-left p:nth-child(3) {
  font-size: 12px;
  line-height: 32px;
  color: #a7a8a8;
}
p span {
  margin-right: 25px;
}
.datails-right {
  padding-top: 34px;
  padding-right: 15px;
}
.datails-right button {
  width: 160px;
  font-size: 18px;
  margin-left: 70px;
}
.icon span {
  line-height: 54px;
  font-size: 14px;
  vertical-align: middle;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon-one {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.icon-two {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.shoucang {
  margin-right: 10px;
  color: #ff363f;
}
</style>
