<template>
    <div class="clearfix">
        <div
            class="fl content"
            v-if="UserInfo.file_size / 1024 / 1024 < 5"
            id="hello"
        >
        <div v-if="UserInfo.fmttype_id === '22'" class="imageparent">
            <el-image
                fit="fill"
                :src="UserInfo.download_url"
                :preview-src-list="srcList"
                class="image"
            >
            </el-image>
        </div>
        <div v-else>
            <iframe
                :src="UserInfo.download_url"
                style="height:760px;width:910px"
                frameborder="0"
                v-if="
                    UserInfo.download_url.substr(UserInfo.download_url.length - 3) ==
                    'mp3'
                "
                id="iframeparent"
            ></iframe>
            <iframe
                :src="srcUrl"
                style="height:760px;width:910px"
                frameborder="0"
                v-else
            ></iframe>
        </div>
        </div>
        <div class="fl content" v-else>
        <div class="tishi">文件过大，请下载后预览</div>
        </div>
    </div>
</template>

<script>
export default {
    props:['switch_data'],
    name: "",
    data() {
        return {
        srcUrl: "",
        UserInfo: "",
        srcList: []
        };
    },
    created() {
        //获取传入的参数
        this.srcUrl=this.$route.query.data.download_url;
        setTimeout(function() {
            const a = document.querySelectorAll("hello");
            
        }, 3000);
    },
    watch:{
        switch_data(val,old){
            if(val != old){
                this.UserInfo = val
                this.srcList = [];
                this.srcList.push(val.download_url);
                this.srcUrl ="https://view.officeapps.live.com/op/view.aspx?src=" + val.download_url;
            }
        }
    },
    mounted() {
        this.UserInfo = JSON.parse(window.localStorage.getItem("item_xiangqing"));
        if (
            this.UserInfo.fmttype_id === "22" ||
            this.UserInfo.fmttype_id === "33" ||
            this.UserInfo.fmttype_id === "44"
        ) {
            this.srcUrl = this.UserInfo.download_url;
        } else {
            this.srcUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + this.UserInfo.download_url;
        }
        this.srcList = [];
        this.srcList.push(this.UserInfo.download_url);
    },
    methods: {
    }
};
</script>

<style></style>
<style scoped lang="less">
.content {
  width: 910px;
  height: 760px;
  background-color: #fff;
}
.tishi {
  text-align: center;
  line-height: 760px;
  font-size: 20px;
}
.imageparent {
  width: 910px;
  height: 760px;
  /* height: 100%; */
  overflow: hidden;
}
.image {
  width: 100%;
  z-index: 1000;
}
/* .imageparent .el-image .el-image-viewer__close {
  margin-top: 300px;
  top: 300px;
  position: fixed;
  top: 140px !important;
} */
/deep/.el-image-viewer__close {
  // margin-top: 300px;
  top: 140px;
}
/deep/.el-image-viewer__canvas {
  margin-top: 100px;
}
</style>
