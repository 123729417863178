<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right" class="nav_top  inner">
            <el-breadcrumb-item :to="{ path: '/resource/resindex' }">备课资源</el-breadcrumb-item>
            <el-breadcrumb-item>{{data.file_name}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
     
</template>

<script>
export default {
    props: ["switch_data"],
    name: '',
    data () {
        return {
            UserInfo:'',
            data: [],
        };
    },
    mounted(){
        this.data = JSON.parse(window.localStorage.getItem("item_xiangqing")); 
    },
     watch: {
    switch_data(val, old) {
      if (val != old) {
        this.data = val;
      }
    },
  },
    methods: {
    }
}
</script>


<style  lang="less" scoped>
.nav_top{
  font-size: 14px;
  line-height: 68px;
  color: #6e7578;
}
</style>
