<template>
  <div>
    <!-- <top></top> -->
    <!-- <navMenu></navMenu> -->
    <div class="inner">
      <route :switch_data="switch_data"></route>
      <detailsTitle :switch_data="switch_data"></detailsTitle>
      <div class="clearfix content">
        <detailsContent class="fl" :switch_data="switch_data"></detailsContent>
        <recommendation
          @switch_articles="switch_articles"
          class="fr"
        ></recommendation>
      </div>
    </div>
  </div>
</template>

<script>
// import { rand } from "../../api/http";
// import axios from "axios";
// import top from "@/components/common/top.vue";
// import navMenu from "@/components/common/navMenu.vue";
import route from "@/components/resource/route";
import detailsTitle from "@/components/resource/detailsTitle";
import detailsContent from "@/components/resource/detailsContent";
import recommendation from "@/components/resource/recommendation";
export default {
  components: {
    // top,
    // navMenu,
    route,
    detailsTitle,
    detailsContent,
    recommendation,
  },
  data() {
    return {
      switch_data: "",
    };
  },
  mounted() {
    // console.log(11111111111);
    var xxx = JSON.parse(localStorage.getItem("item_xiangqing"));
    var hhh = JSON.parse(localStorage.getItem("loginInfo"));
    // this.getRecordRjw(hhh, xxx);
  },
  methods: {
    switch_articles(val) {
      this.switch_data = val;
    },
    // 数据上报
    getRecordRjw(hh, xx) {
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime =
          res.data.result.net_ts - Date.parse(new Date()) / 1000;
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "resource_preview",
          user_id: String(hh.user_id),
          user_role: String(hh.user_type),
          user_name: String(hh.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${hh.user_nicename}` + "在线预览了" + `${xx.file_name}`,
          data: {
            school_name: hh.teacher_info.school_name
              ? `${hh.teacher_info.school_name}`
              : "",
            school_id: hh.teacher_info.school_id
              ? `${hh.teacher_info.school_id}`
              : "",
            grade_name: JSON.parse(localStorage.getItem("data_nianji")),
            grade_id: JSON.parse(localStorage.getItem("zjgradeCopy")),
            subject_name: JSON.parse(localStorage.getItem("data_kemu")),
            subject_id: JSON.parse(localStorage.getItem("zjsidCopy")),
            textbook_id: JSON.parse(localStorage.getItem("data_bookid")),
            textbook_name: xx.book_name,
            file_name: xx.file_name,
            trace_id: JSON.parse(localStorage.getItem("resource_traceid")),
            file_type: localStorage.getItem("file_type"),
            file_format: localStorage.getItem("file_fmt"),
            res_type: JSON.parse(localStorage.getItem("data_ziyuantype")),
            chapter_id: xx.chapter_id,
            chapter_name: JSON.parse(localStorage.getItem("data_zjname")),
          },
        };
        axios.post("http://47.114.114.56:9002/RecordRjw", saydata);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 20px;
}
</style>
