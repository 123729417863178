<template>
    <div>
        <div class="nav">
            <div class="h">
                <svg class="tuijian-logo" aria-hidden="true">
                    <use xlink:href="#icontuijian-copy" />
                </svg>
                精品推荐
            </div>
            <ul>
                <li v-for="(item,index) in tuijianlist" :key="index" @click="xiangqing(index)">
                <div class="list">
                    <div class="fl left">
                    <svg class="list-logo" v-if="item.file_name.substring(item.file_name.length-3)=='ocx'" aria-hidden="true">
                        <use xlink:href="#iconword" />
                    </svg>
                    <svg class="list-logo" v-if="item.file_name.substring(item.file_name.length-3)=='doc'" aria-hidden="true">
                        <use xlink:href="#iconword" />
                    </svg>
                    <svg class="list-logo" v-if="item.file_name.substring(item.file_name.length-3)=='ppt'" aria-hidden="true">
                        <use xlink:href="#iconppt" />
                    </svg>
                    <svg class="list-logo" v-if="item.file_name.substring(item.file_name.length-3)=='ptx'" aria-hidden="true">
                        <use xlink:href="#iconppt" />
                    </svg>
                    <svg class="list-logo" v-if="item.file_name.substring(item.file_name.length-3)=='xls'" aria-hidden="true">
                        <use xlink:href="#iconexcel" />
                    </svg>
                    <svg class="list-logo" v-if="item.file_name.substring(item.file_name.length-3)=='png'" aria-hidden="true">
                        <use xlink:href="#icontupian1"></use>
                    </svg>
                    <svg class="list-logo" v-if="item.file_name.substring(item.file_name.length-3)=='jpg'" aria-hidden="true">
                        <use xlink:href="#icontupian1"></use>
                    </svg>
                    </div>
                    <div class="right">
                    <div :class="{'size':true,'selected':active==item.file_id,}">{{item.file_name}}</div>
                    <div class="liu_box">
                        <div>
                        浏览：
                        <span class="liu-box-span">{{item.downnum + 552}}</span>
                        </div>
                        <div>
                        下载：
                        <span class="liu-box-span">{{item.downnum}}</span>
                        </div>
                    </div>
                    </div>
                </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import URL from "@/api/index.js";
export default {
    name: "",
    data() {
        return {
            tuijianlist: [],
            grade: 1,
            nianji: "",
            subject: 1,
            active: null,
        };
    },
    mounted() {
        this.qingqiu();
    },
    methods: {
        qingqiu() {
            var data = { 
                grade: 1, 
                subject: 1 
            }
            this.$get(URL.recommendfiles2, data).then((res) => {
                // console.log(res);
                this.tuijianlist = res.result.new;
            });
        },
        xiangqing(index) {
            // 详情页面的点击选中样式
            this.active = this.tuijianlist[index].file_id;
            if (this.$route.path !== "/resource/details") {
                this.$router.push({
                    path: "/resource/details",
                    query: {
                        data: this.tuijianlist[index]
                    }
                });
            } else {
                this.$emit("switch_articles", this.tuijianlist[index]);
            }
            localStorage.setItem("item_xiangqing", JSON.stringify(this.tuijianlist[index]));
        },
    },
};
</script>
<style>
</style>
<style  lang="less" scoped >
.nav {
  width: 246px;
  background-color: #fff;
  padding: 0 11px;
}
.h {
  line-height: 51px;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #6e7578;
}
.tuijian-logo {
  width: 21px;
  height: 23px;
  vertical-align: middle;
}
.list {
  padding: 18px 0 18px 0;
  border-top: 1px solid #e3e3e3;
  overflow: hidden;
  
}
.left {
  position: relative;
}
.right{
    padding-left: 20px;
}
.list-logo {
  width: 39px;
  height: 39px;
  position: absolute;
  top: -7px;
  left: -10px;
} 
.size {
  width: 207px;
  line-height: 24px;
  font-size: 14px;
  color: #797979;
  margin-right: 10px;
  margin-bottom: 7px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.size:hover {
  color: #2c84ff;
  cursor: pointer;
}
.selected {
  color: #2c84ff;
}
.selectedself {
  color: #2c84ff;
}
.liu_box {
  font-size: 12px;
  color: #a7a8a8;
}
.liu_box div {
  float: left;
  margin-right: 20px;
}
.liu-box-span {
  color: #3688fc;
}
</style>
